import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  items: [],
  views: {},
  descendants: [],
  auth_items: [],
  success: false,
};

const getters = {
  items: (state) => state.items,
  views: (state) => state.views,
  descendants: (state) => state.descendants,
  auth_items: (state) => state.auth_items,
  success: (state) => state.success,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
  },
  [mutationTypes.GET]: (state) => {
    state.views = {};
    state.descendants = [];
    state.auth_items = [];
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.views = response.views;
    state.descendants = response.descendants;
    state.auth_items = response.auth_items;
  },
  [mutationTypes.GET_FAILURE]: () => {
    state.views = {};
    state.descendants = [];
    state.auth_items = [];
  },
  [mutationTypes.CREATE]: (state) => {
    state.success = false;
  },
  [mutationTypes.CREATE_SUCCESS]: (state, response) => {
    state.success = response.success;
  },
  [mutationTypes.CREATE_FAILURE]: () => {
    state.success = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.success = false;
  },
  [mutationTypes.UPDATE_SUCCESS]: (state, response) => {
    state.success = response.success;
  },
  [mutationTypes.UPDATE_FAILURE]: () => {
    state.success = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.success = false;
  },
  [mutationTypes.DELETE_SUCCESS]: (state, response) => {
    state.success = response.success;
  },
  [mutationTypes.DELETE_FAILURE]: () => {
    state.success = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchRole();
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.GET]: async ({ commit }, payload) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getRole(payload);
      commit(mutationTypes.GET_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createRole(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async ({ commit }, payload) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateRole(payload);
      commit(mutationTypes.UPDATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteRole(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
