import Vue from 'vue';

export default {
  async TeamManagersLog(params = {}) {
    return Vue.axios
      .get('/api/table/team_managers_log_list/', {
        params: params,
      })
      .catch((error) => {
        console.error('ERROR : ' + error);
      });
  },
};
