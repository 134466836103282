import moment from 'moment-timezone';
import VueCookies from 'vue-cookies';

export default {
  form: {
    mem_num: VueCookies.get('usernum'),
    date_type: 'closedt',
    startdate: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    enddate: moment()
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD'),
    startprice: '',
    endprice: '',
    search_type: 0,
    search: '',
    my1: '',
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 20,
    totalElements: 0,
    pages: 1,
  },
  succCnt: 0,
};
