import Vue from 'vue';

export default {
  async fetchCounselreq(params) {
    return Vue.axios.get(`/api/counselreq/data`, {
      params: params,
    });
  },
  async deleteCounselreq(params) {
    return Vue.axios.post(`/api/counselreq/delete`, params);
  },
};
