import Vue from 'vue';

export default {
  async fetchOfficeEtc(params) {
    return Vue.axios.get(`/api/table/officeinfoetc/`, { params: params });
  },
  async updateOfficeEtc(params) {
    return Vue.axios.put(`/api/table/officeinfoetc/update/`, params);
  },
};
