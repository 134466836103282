import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  commission: {},
  user: {},
  bidinfo: {},
  loading: false,
};

const getters = {
  commission: (state) => state.commission,
  user: (state) => state.user,
  bidinfo: (state) => state.bidinfo,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.GET]: (state) => {
    state.commission = {};
    state.user = {};
    state.bidinfo = {};
    state.loading = true;
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.commission = response.commission;
    state.user = response.user;
    state.bidinfo = response.bidinfo;
    state.loading = false;
  },
  [mutationTypes.GET_FAILURE]: (state) => {
    state.commission = {};
    state.user = {};
    state.bidinfo = {};
    state.loading = false;
  },
  [mutationTypes.CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: () => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.GET]: async ({ commit }, payload) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getCommission(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.GET_FAILURE);
      } else {
        commit(mutationTypes.GET_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createCommission(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
