import Vue from 'vue';

export default {
  async createMemCounsel(params) {
    return Vue.axios.post(`/api/table/memcounsel/create/`, params);
  },
  async updateMemCounsel(params) {
    return Vue.axios.put(`/api/table/memcounsel/update/`, params);
  },
  async deleteMemCounsel(params) {
    return Vue.axios.delete(`/api/table/memcounsel/delete/`, {
      params: params,
    });
  },
  async fetchMemCounsel(params) {
    return Vue.axios.get(`/api/table/memcounsel/`, {
      params: params,
    });
  },
};
