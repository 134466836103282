import Vue from 'vue';

export default {
  async fetchCounsel(params) {
    return Vue.axios.get(`/api/user/counsel-list`, {
      params: params,
    });
  },
  async createCounsel(params) {
    return Vue.axios.post(`/api/user/counsel-create`, params);
  },
  async deleteCounsel(params) {
    return Vue.axios.delete(`/api/user/counsel-delete`, params);
  },
};
