import Vue from 'vue';

export default {
  async getMembers() {
    return Vue.axios.get(`/api/user/members`);
  },
  async getManagers() {
    return Vue.axios.get(`/api/user/managers`);
  },
  async getProgressMembers() {
    return Vue.axios.get(`/api/user/progress-members`);
  },
  async fetchUser(params) {
    return Vue.axios.get(`/api/user/list`, {
      params: params,
    });
  },
  async fetchUserDormant(params) {
    return Vue.axios.get(`/api/user/dormant`, {
      params: params,
    });
  },
  async deleteUser(params) {
    return Vue.axios.delete(`/api/user/delete`, params);
  },
};
