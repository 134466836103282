import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    officeno: null,
    officenm: null,
    pre_name: null,
  },
  bizbasic: {},
  loading: false,
};

const getters = {
  form: (state) => state.form,
  bizbasic: (state) => state.bizbasic,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.GET]: (state) => {
    state.bizbasic = {};
    state.loading = true;
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.bizbasic = response;
    state.loading = false;
  },
  [mutationTypes.GET_FAILURE]: (state) => {
    state.bizbasic = {};
    state.loading = false;
  },
};

const actions = {
  [actionTypes.GET]: async ({ commit }) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getBizBasic({
        ...state.form,
      });

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.GET_FAILURE);
      } else {
        commit(mutationTypes.GET_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
