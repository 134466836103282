import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  items: [],
  loading: false,
  form: {
    num: '',
    kind: '',
    comp_name: '',
    comp_num: '',
    content: '',
    contents: {
      U: '',
      S: '',
    },
  },
};

const getters = {
  items: (state) => state.items,
  loading: (state) => state.loading,
  form: (state) => state.form,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
    state.form = {
      num: '',
      kind: '',
      comp_name: '',
      comp_num: '',
      content: '',
      contents: {
        U: '',
        S: '',
      },
    };
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.result;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: (state) => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }, payload) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchCounsel(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit }) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createCounsel({
        ...state.form,
      });
      commit(mutationTypes.CREATE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteCounsel(payload);
      commit(mutationTypes.DELETE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
