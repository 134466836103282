import Vue from 'vue';

export default {
  async TeamManagerList(params = {}) {
    return Vue.axios
      .get('/api/table/team_managers_list/', {
        params: params,
      })
      .catch((error) => {
        console.error('ERROR : ' + error);
      });
  },
  async TeammanagerPartList() {
    return Vue.axios.get('/api/table/team_managers_part_list/').catch((error) => {
      console.error('ERROR : ' + error);
    });
  },
  async TeamManagerDuplicate(id = '') {
    return Vue.axios.get('/api/table/team_managers_duplicate/?id=' + id).catch((error) => {
      console.error('ERROR : ' + error);
    });
  },
  async TeamManagerSave(params = {}) {
    return Vue.axios.post('/api/table/team_managers_create/', params.params).catch((error) => {
      console.error('ERROR : ' + error);
    });
  },
  async TeamManagerFchg(params = {}) {
    return Vue.axios.post('/api/table/team_managers_fchg/', params).catch((error) => {
      console.error('ERROR : ' + error);
    });
  },
  async TeamManagerRead(num = '') {
    return Vue.axios.get('/api/table/team_managers_read/?num=' + num).catch((error) => {
      console.error('ERROR : ' + error);
    });
  },
  async TeamManagersAll() {
    return Vue.axios.get('/api/table/team_managers_all/').catch((error) => {
      console.error('ERROR : ' + error);
    });
  },
};
