import Vue from 'vue';

export default {
  async fetchCommission(params) {
    return Vue.axios.get(`/api/commission/list`, {
      params: params,
    });
  },
  async updateCommission(params) {
    return Vue.axios.put(`/api/commission/update`, params);
  },
  async deleteCommission(params) {
    return Vue.axios.delete(`/api/commission/delete`, params);
  },
};
