import moment from 'moment';
import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
  },
  sales: [],
  teams: [],
  total_count: 0,
  total_smoney: 0,
  total_commission: 0,
  total_payed: 0,
  total_unpayed: 0,
  loading: false,
};

const getters = {
  form: (state) => state.form,
  sales: (state) => state.sales,
  teams: (state) => state.teams,
  total_count: (state) => state.total_count,
  total_smoney: (state) => state.total_smoney,
  total_commission: (state) => state.total_commission,
  total_payed: (state) => state.total_payed,
  total_unpayed: (state) => state.total_unpayed,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.sales = [];
    state.teams = [];
    state.total_count = 0;
    state.total_smoney = 0;
    state.total_commission = 0;
    state.total_payed = 0;
    state.total_unpayed = 0;
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.form.year = response.year;
    state.form.month = response.month;
    state.sales = response.sales;
    state.teams = response.teams;
    state.total_count = response.total_count;
    state.total_smoney = response.total_smoney;
    state.total_commission = response.total_commission;
    state.total_payed = response.total_payed;
    state.total_unpayed = response.total_unpayed;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.form = {
      year: moment().format('YYYY'),
      month: moment().format('MM'),
    };
    state.sales = [];
    state.teams = [];
    state.total_count = 0;
    state.total_smoney = 0;
    state.total_commission = 0;
    state.total_payed = 0;
    state.total_unpayed = 0;
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchSales({
        ...state.form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
