import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  items: [],
  views: {
    name: '',
    auth_item: [],
    assignment: [],
  },
  auth_items: [],
  loading: false,
};

const getters = {
  items: (state) => state.items,
  views: (state) => state.views,
  auth_items: (state) => state.auth_items,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
    state.loading = false;
    state.total = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.total = 0;
  },
  [mutationTypes.GET]: (state) => {
    state.views = {
      name: '',
      auth_item: [],
      assignment: [],
    };
    state.loading = true;
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.views = response.views;
    state.auth_items = response.auth_items;
    state.loading = false;
  },
  [mutationTypes.GET_FAILURE]: () => {
    state.views = {
      name: '',
      auth_item: [],
      assignment: [],
    };
    state.loading = false;
  },
  [mutationTypes.CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: () => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchAssignment();
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.GET]: async ({ commit }, payload) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getAssignment(payload);
      commit(mutationTypes.GET_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createAssignment(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
      dispatch(actionTypes.GET, {
        id: payload.userid,
      });
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteAssignment(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
      dispatch(actionTypes.GET, {
        id: payload.userid,
      });
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
