import moment from 'moment-timezone';
import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    startdate: moment()
      .subtract(1, 'year')
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD'),
    enddate: moment()
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD'),
    kind: '',
    mng_num: -1,
    search_type: 'comp_name',
    search: '',
    groupCom: false,
    sort: 'writedate',
    desc: 'desc',
    folder_num: null,
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 20,
    totalElements: 0,
    pages: 1,
  },
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
};

const mutations = {
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: () => {
    state.loading = false;
  },
  [mutationTypes.FETCH]: (state, page) => {
    state.items = [];
    state.loading = true;
    state.pagination.page = page;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
    state.form.mng_num = response.form.mng_num;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
};

const actions = {
  [actionTypes.DELETE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteMemCounsel(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
      dispatch(actionTypes.FETCH);
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
  [actionTypes.FETCH]: async ({ commit }, page = 1) => {
    commit(mutationTypes.FETCH, page);
    try {
      let form = state.form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      form.shopkey = window.siteconfig.name;
      const { data } = await api.fetchMemCounsel({
        ...form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
