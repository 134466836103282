import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    mem_num: '',
  },
  pagination: {
    page: 1,
    sort: 'uptime',
    desc: 'desc',
    itemsPerPage: 50,
  },
  items: [],
  loading: false,
  total: 0,
};

const getters = {
  form: (state) => state.form,
  pagination: (state) => state.pagination,
  items: (state) => state.items,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
    state.loading = false;
    state.total = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.total = 0;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchUserLog({
        ...state.form,
        ...state.pagination,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
