import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    bidid: null,
    page: 1,
    sort: '',
    desc: '',
    itemsPerPage: 20,
    constdate: null,
    column: 'officenm',
    word: '',
  },
  succom: [],
  total: 0,
  loading: false,
};

const getters = {
  form: (state) => state.form,
  total: (state) => state.total,
  succom: (state) => state.succom,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.succom = [];
    state.total = 0;
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.succom = response.items;
    state.total = response.page.totalcnt;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.succom = [];
    state.total = 0;
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchV3BidSuccom({
        ...state.form,
      });

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
