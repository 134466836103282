import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    items: [],
  },
  items: [],
  loading: false,
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.form = {
      items: [],
    };
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.form.items = response.result;
    state.items = response.result;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.form = {
      items: [],
    };
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.CREATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.UPDATE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.UPDATE_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: (state) => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchCounselFolder();
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createCounselFolder(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
      dispatch(actionTypes.FETCH);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async ({ commit, dispatch }) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateCounselFolder({
        ...state.form,
      });
      commit(mutationTypes.UPDATE_SUCCESS, data);
      dispatch(actionTypes.FETCH);
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteCounselFolder(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
      dispatch(actionTypes.FETCH);
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
