import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  params: {
    sort: 'seq',
    desc: 'desc',
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 20,
    totalElements: 0,
    pages: 1,
  },
  dataProps: {
    seq: {
      text: '일련번호',
    },
    logcase: {
      text: '상태',
      items: [
        {
          value: 'LOGIN',
          text: '로그인',
        },
        {
          value: 'ETC',
          text: '기타',
        },
      ],
    },
    mem_id: {
      text: '회원번호',
      items: [],
    },
    mem_name: {
      text: '이름',
    },
    ip_addr: {
      text: '아이피주소',
    },
    uptime: {
      text: '접속일시',
    },
  },
};

const getters = {
  params: (state) => state.params,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
  dataProps: (state) => state.dataProps,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  TeamManagersAll: (state, data) => {
    state.dataProps.mem_id.items = data;
    //console.log("mutationTypes", state.dataProps.mem_id);
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      var params = state.params;
      params.page = state.pagination.page;
      params.size = state.pagination.size;
      const { data } = await api.TeamManagersLog({
        ...params,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  TeamManagersAll: async ({ commit }) => {
    const { data } = await api.TeamManagersAll();
    commit('TeamManagersAll', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
