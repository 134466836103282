import Vue from 'vue';

export default {
  async createAssignment(params) {
    return Vue.axios.post(`/api/assignment/create`, params);
  },
  async deleteAssignment(params) {
    return Vue.axios.delete(`/api/assignment/delete`, {
      params: params,
    });
  },
  async fetchAssignment(params) {
    return Vue.axios.get(`/api/assignment/list`, {
      params: params,
    });
  },
  async getAssignment(params) {
    return Vue.axios.get(`/api/assignment/data`, {
      params: params,
    });
  },
};
