import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  itemcode: [],
  itemcode_loading: false,
  g2b_items: [],
  g2b_loading: false,
  jcombasic: [],
  jcom_loading: false,
};

const getters = {
  itemcode: (state) => state.itemcode,
  itemcode_loading: (state) => state.itemcode_loading,
  g2b_items: (state) => state.g2b_items,
  g2b_loading: (state) => state.g2b_loading,
  jcombasic: (state) => state.jcombasic,
  jcom_loading: (state) => state.jcom_loading,
};

const mutations = {
  [mutationTypes.ITEMCODE]: (state) => {
    state.itemcode = [];
    state.itemcode_loading = true;
  },
  [mutationTypes.ITEMCODE_SUCCESS]: (state, response) => {
    state.itemcode = response.result;
    state.itemcode_loading = false;
  },
  [mutationTypes.ITEMCODE_FAILURE]: (state) => {
    state.itemcode = [];
    state.itemcode_loading = false;
  },
  [mutationTypes.G2BCODES]: (state) => {
    state.g2b_items = [];
    state.g2b_loading = true;
  },
  [mutationTypes.G2BCODES_SUCCESS]: (state, response) => {
    state.g2b_items = response.result;
    state.g2b_loading = false;
  },
  [mutationTypes.G2BCODES_FAILURE]: (state) => {
    state.g2b_items = [];
    state.g2b_loading = false;
  },
  [mutationTypes.JCOMBASIC]: (state) => {
    state.jcombasic = [];
    state.jcom_loading = true;
  },
  [mutationTypes.JCOMBASIC_SUCCESS]: (state, response) => {
    state.jcombasic = response.result;
    state.jcom_loading = false;
  },
  [mutationTypes.JCOMBASIC_FAILURE]: (state) => {
    state.jcombasic = [];
    state.jcom_loading = false;
  },
};

const actions = {
  [actionTypes.ITEMCODE]: async ({ commit }, payload) => {
    commit(mutationTypes.ITEMCODE);
    try {
      const { data } = await api.fetchItemcode(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.ITEMCODE_FAILURE);
      } else {
        commit(mutationTypes.ITEMCODE_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.ITEMCODE_FAILURE);
    }
  },
  [actionTypes.G2BCODES]: async ({ commit }, payload) => {
    commit(mutationTypes.G2BCODES);
    try {
      const { data } = await api.fetchG2bcodes(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.G2BCODES_FAILURE);
      } else {
        commit(mutationTypes.G2BCODES_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.G2BCODES_FAILURE);
    }
  },
  [actionTypes.JCOMBASIC]: async ({ commit }, payload) => {
    commit(mutationTypes.JCOMBASIC);
    try {
      const { data } = await api.fetchJcombasic(payload);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.JCOMBASIC_FAILURE);
      } else {
        commit(mutationTypes.JCOMBASIC_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.JCOMBASIC_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
