import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  office: {
    id: 0,
    officename: '',
    officeno: '',
    prenamej: '',
    phone: '',
    fax: '',
    addr: '',
  },
  upjong: [],
  g2b: [],
  loading: false,
};

const getters = {
  office: (state) => state.office,
  upjong: (state) => state.upjong,
  g2b: (state) => state.g2b,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.office = {};
    state.upjong = [];
    state.g2b = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.office = response.office;
    state.upjong = response.upjong;
    state.g2b = response.g2b;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.office = {};
    state.upjong = [];
    state.g2b = [];
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }, payload) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchMarketingDetail(payload);
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
