import Vue from 'vue';

export default {
  async getUser(params) {
    return Vue.axios.get(`/api/user/data`, {
      params: params,
    });
  },
  async updateUser(params) {
    return Vue.axios.put(`/api/user/update`, params);
  },
  async groupInit(params) {
    return Vue.axios.put(`/api/user/groupinit`, params);
  },
  async passInit(params) {
    return Vue.axios.put(`/api/user/passinit`, params);
  },
  async saveMemo(params) {
    return Vue.axios.put(`/api/user/memo`, params);
  },
};
