import Vue from 'vue';

export default {
  async createMemMarketingFolderData(params) {
    return Vue.axios.post(`/api/table/memmarketingfolderdata/create`, params);
  },
  async deleteMemMarketingFolderData(params) {
    return Vue.axios.delete(`/api/table/memmarketingfolderdata/delete`, {
      params: params,
    });
  },
};
