import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {};
const getters = {};
const mutations = {
  [mutationTypes.CREATE]: () => {},
  [mutationTypes.CREATE_SUCCESS]: () => {},
  [mutationTypes.CREATE_FAILURE]: () => {},
  [mutationTypes.DELETE]: () => {},
  [mutationTypes.DELETE_SUCCESS]: () => {},
  [mutationTypes.DELETE_FAILURE]: () => {},
};

const actions = {
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createCounselFolderData(payload);
      commit(mutationTypes.CREATE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteCounselFolderData(payload);
      commit(mutationTypes.DELETE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
