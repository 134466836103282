import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    officeno: '',
    tel: '',
    fax: '',
  },
  loading: false,
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    //state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    if (response) {
      state.form.tel = response.tel;
      state.form.fax = response.fax;
    }
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.UPDATE_SUCCESS]: (state) => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      let form = state.form;
      const { data } = await api.fetchOfficeEtc({
        ...form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async ({ commit }, payload) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateOfficeEtc(payload);
      commit(mutationTypes.UPDATE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
