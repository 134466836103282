import Vue from 'vue';

export default {
  async createOperation(params) {
    return Vue.axios.post(`/api/operation/create`, params);
  },
  async updateOperation(params) {
    return Vue.axios.put(`/api/operation/update`, params);
  },
  async deleteOperation(params) {
    return Vue.axios.delete(`/api/operation/delete`, {
      params: params,
    });
  },
  async fetchOperation(params) {
    return Vue.axios.get(`/api/operation/list`, {
      params: params,
    });
  },
  async getOperation(params) {
    return Vue.axios.get(`/api/operation/data`, {
      params: params,
    });
  },
};
