import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    bidtype: 'con',
    whereis: 'g2b',
    itemcode: '',
    startdate: '',
    enddate: '',
    location: '',
    local: '',
    rank: 'all',
    officeno: '',
    sess_team_id: '',
    sess_staff_num: '',
    page: 1,
    itemsPerPage: 20,
  },
  chartItems: {},
  items: [],
  bizbasic: {},
  loading: false,
  total: {
    bid: 0,
    suc: 0,
  },
};

const getters = {
  form: (state) => state.form,
  chartItems: (state) => state.chartItems,
  items: (state) => state.items,
  bizbasic: (state) => state.bizbasic,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.chartItems = {};
    state.items = [];
    state.bizbasic = {};
    state.loading = true;
    state.total = {
      bid: 0,
      suc: 0,
    };
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.chartItems = response.chartItems;
    state.items = response.items;
    state.bizbasic = response.bizbasic;
    state.loading = false;
    state.total = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.chartItems = {};
    state.items = [];
    state.bizbasic = {};
    state.loading = false;
    state.total = {
      bid: 0,
      suc: 0,
    };
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchOfficeanal({
        ...state.form,
      });

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
