import Vue from 'vue';

export default {
  async fetchResult(params) {
    return Vue.axios.get(`/api/user/result-list`, {
      params: params,
    });
  },
  async createResult(params) {
    return Vue.axios.post(`/api/user/result-create`, params);
  },
  async updateResult(params) {
    return Vue.axios.put(`/api/user/result-update`, params);
  },
  async deleteResult(params) {
    return Vue.axios.delete(`/api/user/result-delete`, params);
  },
};
