import Vue from 'vue';

export default {
  async getNews(params) {
    return Vue.axios.get('/api/mainpage/news/', {
      params: params,
    });
  },
  async getNotice(params) {
    return Vue.axios.get('/api/mainpage/notice/', {
      params: params,
    });
  },
  async getNoticeView(id) {
    return Vue.axios.get('/api/mainpage/noticeview/', {
      params: id,
    });
  },
};
