import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    datetype: 'cont_start',
    startdate: null,
    enddate: null,
    loyalty1: null,
    loyalty2: null,
    bidtype: 'con',
    progress: 'right',
    mng_num: null,
    counseler_num: null,
    itemcode: null,
    column: 'officename',
    word: null,
    itemsPerPage: 20,
    page: 1,
    kind: null,
    sort: 'num',
    desc: 'desc',
  },
  managers: {},
  members: [],
  progress_members: [],
  total: 0,
  items: [],
  loading: false,
};

const getters = {
  managers: (state) => state.managers,
  members: (state) => state.members,
  progress_members: (state) => state.progress_members,
  total: (state) => state.total,
  items: (state) => state.items,
  loading: (state) => state.loading,
  form: (state) => state.form,
  loginuser: (state) => (usernum) => state.managers[usernum],
};

const mutations = {
  [mutationTypes.MEMBERS]: (state) => {
    state.members = [];
  },
  [mutationTypes.MEMBERS_SUCCESS]: (state, response) => {
    state.members = response.items;
  },
  [mutationTypes.MEMBERS_FAILURE]: (state) => {
    state.members = [];
  },
  [mutationTypes.MANAGERS]: (state) => {
    state.managers = {};
  },
  [mutationTypes.MANAGERS_SUCCESS]: (state, response) => {
    state.managers = response;
  },
  [mutationTypes.MANAGERS_FAILURE]: (state) => {
    state.managers = {};
  },
  [mutationTypes.PROGRESS_MEMBERS]: (state) => {
    state.progress_members = [];
  },
  [mutationTypes.PROGRESS_MEMBERS_SUCCESS]: (state, response) => {
    state.progress_members = response.items;
  },
  [mutationTypes.PROGRESS_MEMBERS_FAILURE]: (state) => {
    state.progress_members = [];
  },
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.result;
    state.total = response.total;
    state.form.mng_num = response.form.mng_num;
    state.form.counseler_num = response.form.counseler_num;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.DORMANT]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.DORMANT_SUCCESS]: (state, response) => {
    state.items = response.result;
    state.total = response.total;
    state.loading = false;
  },
  [mutationTypes.DORMANT_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: (state) => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.MEMBERS]: async ({ commit }) => {
    commit(mutationTypes.MEMBERS);
    try {
      const { data } = await api.getMembers();
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.MEMBERS_FAILURE);
      } else {
        commit(mutationTypes.MEMBERS_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.MEMBERS_FAILURE);
    }
  },
  [actionTypes.MANAGERS]: async ({ commit }) => {
    commit(mutationTypes.MANAGERS);
    try {
      const { data } = await api.getManagers();
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.MANAGERS_FAILURE);
      } else {
        commit(mutationTypes.MANAGERS_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.MANAGERS_FAILURE);
    }
  },
  [actionTypes.PROGRESS_MEMBERS]: async ({ commit }) => {
    commit(mutationTypes.PROGRESS_MEMBERS);
    try {
      const { data } = await api.getProgressMembers();
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.PROGRESS_MEMBERS_FAILURE);
      } else {
        commit(mutationTypes.PROGRESS_MEMBERS_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.PROGRESS_MEMBERS_FAILURE);
    }
  },
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchUser({
        ...state.form,
      });
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.DORMANT]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchUserDormant({
        ...state.form,
      });
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.DORMANT_FAILURE);
      } else {
        commit(mutationTypes.DORMANT_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.DORMANT_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteUser(payload);
      commit(mutationTypes.DELETE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
