import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  pagination: {
    page: 1,
    sort: '',
    desc: '',
    itemsPerPage: 20,
  },
  items: [],
  loading: false,
  total: 0,
};

const getters = {
  pagination: (state) => state.pagination,
  items: (state) => state.items,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
    state.loading = false;
    state.total = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.total = 0;
  },
  [mutationTypes.DELETE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: () => {
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: () => {
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchNoti({
        ...state.pagination,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit, dispatch }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteNoti(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
      dispatch(actionTypes.FETCH);
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
