import Vue from 'vue';

export default {
  async fetchContract(params) {
    return Vue.axios.get(`/api/user/contract-list`, {
      params: params,
    });
  },
  async createContract(params) {
    return Vue.axios.post(`/api/user/contract-create`, params);
  },
  async updateContract(params) {
    return Vue.axios.put(`/api/user/contract-update`, params);
  },
  async deleteContract(params) {
    return Vue.axios.delete(`/api/user/contract-delete`, params);
  },
};
