import Vue from 'vue';

export default {
  async createChildRole(params) {
    return Vue.axios.post(`/api/role/child-create`, params);
  },
  async deleteChildRole(params) {
    return Vue.axios.delete(`/api/role/child-delete`, {
      params: params,
    });
  },
};
