import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    date_type: 'writedt',
    startdate: '',
    enddate: '',
    bidtype: 'con',
    itemcode: '',
    g2bcode: '',
    location: '',
    local_code: '',
    ulevel: '',
    price_type: 'presum',
    startprice: '',
    endprice: '',
    bidproc: 'B',
    column: 'notinum',
    word: '',
    page: 1,
    sort: 'writedt',
    desc: 'desc',
  },
  choices: {
    date_type: [
      {
        text: '입력일',
        value: 'writedt',
      },
      {
        text: '투찰마감일',
        value: 'closedt',
      },
      {
        text: '입찰일',
        value: 'constdt',
      },
    ],
    column_type: [
      {
        text: '공고번호',
        value: 'notinum',
      },
      {
        text: '공고명',
        value: 'constnm',
      },
      {
        text: '발주기관',
        value: 'org',
      },
    ],
    price_type: [
      {
        text: '기초금액',
        value: 'basic',
      },
      {
        text: '추정가격',
        value: 'presum',
      },
    ],
  },
  items: [],
  loading: false,
  total: 0,
};

const getters = {
  form: (state) => state.form,
  choices: (state) => state.choices,
  items: (state) => state.items,
  loading: (state) => state.loading,
  total: (state) => state.total,
};

const mutations = {
  [mutationTypes.FETCH]: (state, data) => {
    state.form = data ? Object.assign({}, state.form, data) : state.form;
    state.items = [];
    state.loading = true;
    state.total = 0;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.items;
    state.loading = false;
    state.total = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.total = 0;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit, getters }, data) => {
    commit(mutationTypes.FETCH, data);
    try {
      const { data } = await api.fetchBidsearch(getters.form);

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
