import api from '@/api';
import * as actionTypes from './action-types';
import * as mutationTypes from './mutation-types';

export default {
  [actionTypes.FETCH_MYRESULT]: async ({ commit, state }, page = 1) => {
    commit(mutationTypes.FETCH_MYRESULT, page);
    try {
      let form = state.form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      const { data } = await api.fetchMyResult({ ...form });
      commit(mutationTypes.FETCH_MYRESULT_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_MYRESULT_FAILURE);
    }
  },
};
