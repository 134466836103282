import Vue from 'vue';

export default {
  async fetchCounselFolder() {
    return Vue.axios.get(`/api/table/memcounselfolder/list`);
  },
  async createCounselFolder(params) {
    return Vue.axios.post(`/api/table/memcounselfolder/create`, params);
  },
  async updateCounselFolder(params) {
    return Vue.axios.put(`/api/table/memcounselfolder/update`, params);
  },
  async deleteCounselFolder(params) {
    return Vue.axios.delete(`/api/table/memcounselfolder/delete`, {
      params: params,
    });
  },
};
