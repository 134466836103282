import Vue from 'vue';

export default {
  async createRole(params) {
    return Vue.axios.post(`/api/role/create`, params);
  },
  async updateRole(params) {
    return Vue.axios.put(`/api/role/update`, params);
  },
  async deleteRole(params) {
    return Vue.axios.delete(`/api/role/delete`, {
      params: params,
    });
  },
  async fetchRole(params) {
    return Vue.axios.get(`/api/role/list`, {
      params: params,
    });
  },
  async getRole(params) {
    return Vue.axios.get(`/api/role/data`, {
      params: params,
    });
  },
};
