import Vue from 'vue';

export default {
  async createUserNotice(params) {
    return Vue.axios.post(`/api/usernotice/create/`, params);
  },
  async deleteUserNotice(params) {
    return Vue.axios.delete(`/api/usernotice/delete/`, { params: params });
  },
  async fetchUserNotice(params) {
    return Vue.axios.get(`/api/usernotice/`, { params: params });
  },
  async getUserNotice(params) {
    return Vue.axios.get(`/api/usernotice/get/`, { params: params });
  },
  async updateUserNotice(params) {
    return Vue.axios.put(`/api/usernotice/update/`, params);
  },
};
