import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  params: {
    state: 'Y',
    sort: 'num',
    desc: 'desc',
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 15,
    totalElements: 0,
    pages: 1,
  },
  dataProps: {
    num: {
      text: '일련번호',
    },
    id: {
      text: '아이디',
    },
    pass: {
      text: '비밀번호',
    },
    name: {
      text: '이름',
    },
    part: {
      text: '부서',
      items: [],
    },
    work_position: {
      text: '직위',
    },
    level: {
      text: '타입',
      items: [
        {
          value: '1',
          text: '관리자',
        },
        {
          value: '2',
          text: '분석/상담',
        },
        {
          value: '3',
          text: '분석원',
        },
        {
          value: '4',
          text: '상담원',
        },
      ],
    },
    tel: {
      text: '전화번호',
    },
    sort: {
      text: '정렬',
    },
    work_text: {
      text: '업무',
    },
    memo: {
      text: '메모',
    },
    state: {
      text: '상태',
      items: [
        {
          value: 'Y',
          text: '사용',
        },
        {
          value: 'N',
          text: '정지',
        },
        {
          value: 'D',
          text: '삭제',
        },
        {
          value: 'O',
          text: '퇴사',
        },
      ],
    },
    last_login_dt: {
      text: '최종로그인',
    },
    last_login_ip: {
      text: '로그인IP',
    },
    join_date: {
      text: '가입일',
    },
    out_date: {
      text: '탈퇴일',
    },
    mobile: {
      text: '휴대전화',
    },
    fax: {
      text: '팩스',
    },
    use_sms_login: {
      text: 'SMS인증',
      items: [
        {
          value: 'y',
          text: '예',
        },
        {
          value: 'n',
          text: '아니오',
        },
      ],
    },
    sms_expires: {
      text: 'sms_expires',
    },
    senumber: {
      text: 'senumber',
    },
    counseler_cnt: {
      text: '상담',
    },
    mem_cnt: {
      text: '분석',
    },
  },
};

const getters = {
  params: (state) => state.params,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
  dataProps: (state) => state.dataProps,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  TeammanagerPartList: (state, data) => {
    state.dataProps.part.items = data;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      var params = state.params;
      params.page = state.pagination.page;
      params.size = state.pagination.size;
      const { data } = await api.TeamManagerList({
        ...params,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  TeammanagerPartList: async ({ commit }) => {
    const { data } = await api.TeammanagerPartList();
    commit('TeammanagerPartList', data);
  },
  TeamManagerDuplicate: async ({ commit }, id) => {
    const { data } = await api.TeamManagerDuplicate(id);
    return data;
  },
  TeamManagerSave: async ({ commit }, params) => {
    const { data } = await api.TeamManagerSave(params);
    return data;
  },
  TeamManagerFchg: async ({ commit }, params) => {
    const { data } = await api.TeamManagerFchg(params);
    return data;
  },
  TeamManagerRead: async ({ commit }, num) => {
    const { data } = await api.TeamManagerRead(num);
    return data;
  },
  TeamManagersAll: async () => {
    const { data } = await api.TeamManagersAll();
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
