import Vue from 'vue';

export default {
  async createCounselFolderData(params) {
    return Vue.axios.post(`/api/table/memcounselfolderdata/create`, params);
  },
  async deleteCounselFolderData(params) {
    return Vue.axios.delete(`/api/table/memcounselfolderdata/delete`, {
      params: params,
    });
  },
};
