import Vue from 'vue';

export default {
  async getCommission(params) {
    return Vue.axios.get(`/api/commission/data`, {
      params: params,
    });
  },
  async createCommission(params) {
    return Vue.axios.post(`/api/commission/create`, params);
  },
};
