import Vue from 'vue';

export default {
  async addCustom(params) {
    //console.log(params);
    return Vue.axios.post(`/api/mybid/bidsearch/addcustom/`, params);
  },
  async createMyBidMemo(params) {
    return Vue.axios.post(`/api/mybid/memo/create/`, params);
  },
  async fetchMyBid(params) {
    return Vue.axios.get(`/api/mybid/`, { params: params });
  },
  async fetchMyBidSearch(params) {
    return Vue.axios.get(`/api/mybid/bidsearch/`, { params: params });
  },
  async fetchMyResult(params) {
    return Vue.axios.get(`/api/mybid/myresult/`, { params: params });
  },
  async myBidShowAmount(params) {
    return Vue.axios.post(`/api/mybid/show/amount/`, params);
  },
  async myBidRegist(params) {
    return Vue.axios.post(`/api/mybid/regist/`, params);
  },
  async myBidTuchal(params) {
    return Vue.axios.post(`/api/mybid/tuchal/`, params);
  },
};
