import Vue from 'vue';
import App from './App.vue';
import router from './router/index.admin.js';
import { store } from './store';
import VueAxios from './plugins/vue-axios';
import VueClipboard from 'vue-clipboard2';
import vuetify from './plugins/vuetify';
import './plugins/quill';
import './plugins/sweet-alert';
import './filters';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import HighchartsMore from 'highcharts/highcharts-more';

stockInit(Highcharts);
HighchartsMore(Highcharts);
Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
});
Vue.use(HighchartsVue);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  VueAxios,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
