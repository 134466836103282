export default {
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 10,
    totalElements: 0,
    pages: 1,
  },
};
