import Vue from 'vue';

export default {
  async fetchItemcode(params) {
    return Vue.axios.get(`/api/code/itemcode`, {
      params: params,
    });
  },
  async fetchG2bcodes(params) {
    return Vue.axios.get(`/api/code/g2bcode`, {
      params: params,
    });
  },
  async fetchJcombasic(params) {
    return Vue.axios.get(`/api/code/jcombasiccode`, {
      params: params,
    });
  },
};
