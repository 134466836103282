import api from './api';
import * as actionTypes from './action-types';

const M_SET_NEWS = 'SET_NEWS';
const M_SET_NOTICE = 'SET_NOTICE';
const M_SET_NOTICE_VIEW = 'SET_NOTICE_VIEW';

const state = {
  news: {},
  notice: [],
  noticeview: [],
};

const getters = {
  news: (state) => {
    return (type) => (state.news.hasOwnProperty(type) ? state.news[type] : []);
  },
  notice: (state) => state.notice,
  noticeview: (state) => state.noticeview,
};

const mutations = {
  [M_SET_NEWS]: (state, data) => {
    state.news = data;
  },
  [M_SET_NOTICE]: (state, data) => {
    state.notice = data;
  },
  [M_SET_NOTICE_VIEW]: (state, data) => {
    state.noticeview = data;
  },
};

const actions = {
  [actionTypes.GET_NEWS]: async ({ commit }, params) => {
    try {
      const { data } = await api.getNews(params);
      commit(M_SET_NEWS, data);
    } catch (e) {
      commit(M_SET_NEWS, {});
    }
  },
  [actionTypes.GET_NOTICE]: async ({ commit }, params) => {
    try {
      const { data } = await api.getNotice(params);
      commit(M_SET_NOTICE, data);
    } catch (e) {
      commit(M_SET_NOTICE, {});
    }
  },
  [actionTypes.GET_NOTICE_VIEW]: async ({ commit }, id) => {
    try {
      const { data } = await api.getNoticeView({
        id: id,
      });
      commit(M_SET_NOTICE_VIEW, data);
    } catch (e) {
      commit(M_SET_NOTICE_VIEW, {});
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
