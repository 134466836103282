import moment from 'moment';
import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    mngtype: 0,
  },
  memstat: [],
  teams: [],
  total_members: 0,
  total_new: 0,
  total_renewal: 0,
  total_stop: 0,
  total_progress: 0,
  loading: false,
};

const getters = {
  form: (state) => state.form,
  memstat: (state) => state.memstat,
  teams: (state) => state.teams,
  total_members: (state) => state.total_members,
  total_new: (state) => state.total_new,
  total_renewal: (state) => state.total_renewal,
  total_stop: (state) => state.total_stop,
  total_progress: (state) => state.total_progress,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.memstat = [];
    state.teams = [];
    state.total_members = 0;
    state.total_new = 0;
    state.total_renewal = 0;
    state.total_stop = 0;
    state.total_progress = 0;
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.memstat = response.memstat;
    state.teams = response.teams;
    state.total_members = response.total_members;
    state.total_new = response.total_new;
    state.total_renewal = response.total_renewal;
    state.total_stop = response.total_stop;
    state.total_progress = response.total_progress;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.memstat = [];
    state.teams = [];
    state.total_members = 0;
    state.total_new = 0;
    state.total_renewal = 0;
    state.total_stop = 0;
    state.total_progress = 0;
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchMemStat({
        ...state.form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
