import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

export default {
  [actionTypes.FETCH]: async ({ commit, state }, { bidtype, officeno, page }) => {
    commit(mutationTypes.FETCH, page);
    try {
      let form = {};
      form.bidtype = bidtype;
      form.officeno = officeno;
      form.page = page;
      form.size = state.pagination.size;
      const { data } = await api.fetchPowerkeyD2b({ ...form });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};
