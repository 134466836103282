import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  loading: false,
  group_loading: false,
  pass_loading: false,
  memo_loading: false,
  groupid: [],
  form: {
    id: null,
    cont_start: null,
    cont_end: null,
    cs_menu: false,
    ce_menu: false,
    progress: 'right',
    manager: null,
    counsel: null,
    con_gubun: null,
    memo: '',
  },
};

const getters = {
  form: (state) => state.form,
  loading: (state) => state.loading,
  group_loading: (state) => state.group_loading,
  pass_loading: (state) => state.pass_loading,
  memo_loading: (state) => state.memo_loading,
  groupid: (state) => state.groupid,
};

const mutations = {
  [mutationTypes.GET]: (state) => {
    state.form = {};
    state.groupid = [];
    state.loading = true;
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.form = response.user;
    state.groupid = response.groupid;
    state.loading = false;
  },
  [mutationTypes.GET_FAILURE]: (state) => {
    state.form = {};
    state.groupid = [];
    state.loading = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.UPDATE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.UPDATE_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.GROUPINIT]: (state) => {
    state.group_loading = true;
  },
  [mutationTypes.GROUPINIT_SUCCESS]: (state) => {
    state.group_loading = false;
  },
  [mutationTypes.GROUPINIT_FAILURE]: (state) => {
    state.group_loading = false;
  },
  [mutationTypes.PASSINIT]: (state) => {
    state.pass_loading = true;
  },
  [mutationTypes.PASSINIT_SUCCESS]: (state) => {
    state.pass_loading = false;
  },
  [mutationTypes.PASSINIT_FAILURE]: (state) => {
    state.pass_loading = false;
  },
  [mutationTypes.MEMO]: (state) => {
    state.memo_loading = true;
  },
  [mutationTypes.MEMO_SUCCESS]: (state) => {
    state.memo_loading = false;
  },
  [mutationTypes.MEMO_FAILURE]: (state) => {
    state.memo_loading = false;
  },
};

const actions = {
  [actionTypes.GET]: async ({ commit }, payload) => {
    commit(mutationTypes.GET);
    try {
      //console.log(payload);
      const { data } = await api.getUser(payload);
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.GET_FAILURE);
      } else {
        commit(mutationTypes.GET_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async ({ commit }) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateUser({
        ...state.form,
      });
      commit(mutationTypes.UPDATE_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
  [actionTypes.GROUPINIT]: async ({ commit }, payload) => {
    commit(mutationTypes.GROUPINIT);
    try {
      const { data } = await api.groupInit(payload);
      commit(mutationTypes.GROUPINIT_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.GROUPINIT_FAILURE);
    }
  },
  [actionTypes.PASSINIT]: async ({ commit }, payload) => {
    commit(mutationTypes.PASSINIT);
    try {
      const { data } = await api.passInit(payload);
      commit(mutationTypes.PASSINIT_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.PASSINIT_FAILURE);
    }
  },
  [actionTypes.MEMO]: async ({ commit }, payload) => {
    commit(mutationTypes.MEMO);
    try {
      const { data } = await api.saveMemo(payload);
      commit(mutationTypes.MEMO_SUCCESS);
      return data;
    } catch (e) {
      commit(mutationTypes.MEMO_FAILURE);
      return e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
