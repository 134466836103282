import VueCookies from 'vue-cookies';
import api from '@/api';
import * as actionTypes from './action-types';
import * as mutationTypes from './mutation-types';

export default {
  [actionTypes.ADD_CUSTOM]: async ({ commit }, payload) => {
    commit(mutationTypes.ADD_CUSTOM);
    try {
      const { data } = await api.addCustom(payload);
      commit(mutationTypes.ADD_CUSTOM_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.ADD_CUSTOM_FAILURE);
    }
  },
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createMyBidMemo(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.FETCH]: async ({ commit, state }, page = 1) => {
    commit(mutationTypes.FETCH, page);
    try {
      let form = state.mybid_form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      const { data } = await api.fetchMyBid({ ...form });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.FETCH_BIDSEARCH]: async ({ commit, state }, { mem_num, page = 1 }) => {
    commit(mutationTypes.FETCH_BIDSEARCH, page);
    try {
      let form = state.bidsearch_form;
      form.mem_num = mem_num;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      const { data } = await api.fetchMyBidSearch({
        ...form,
      });
      commit(mutationTypes.FETCH_BIDSEARCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_BIDSEARCH_FAILURE);
    }
  },
  // [actionTypes.FETCH_MYRESULT]: async ({
  //   commit,
  //   state
  // }, {
  //   mem_num,
  //   page = 1
  // }) => {
  //   commit(mutationTypes.FETCH_MYRESULT, page);
  //   try {
  //     let form = state.mybid_form;
  //     form.mem_num = mem_num;
  //     form.page = state.pagination.page;
  //     form.size = state.pagination.size;
  //     const {
  //       data
  //     } = await api.fetchMyResult({
  //       ...form
  //     });
  //     commit(mutationTypes.FETCH_MYRESULT_SUCCESS, data);
  //   } catch (e) {
  //     commit(mutationTypes.FETCH_MYRESULT_FAILURE);
  //   }
  // },
  [actionTypes.SHOW_AMOUNT]: async ({ commit }, payload) => {
    commit(mutationTypes.SHOW_AMOUNT);
    try {
      const { data } = await api.myBidShowAmount(payload);
      commit(mutationTypes.SHOW_AMOUNT_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.SHOW_AMOUNT_FAILURE);
    }
  },
  [actionTypes.REGIST]: async ({ commit }, payload) => {
    commit(mutationTypes.REGIST);
    try {
      const { data } = await api.myBidRegist(payload);
      commit(mutationTypes.REGIST_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.REGIST_FAILURE);
    }
  },
  [actionTypes.TUCHAL]: async ({ commit }, payload) => {
    commit(mutationTypes.TUCHAL);
    try {
      const { data } = await api.myBidTuchal(payload);
      commit(mutationTypes.TUCHAL_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.TUCHAL_FAILURE);
    }
  },
  [actionTypes.CREATE_MEMO]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE_MEMO);
    try {
      const { data } = await api.createMyBidMemo(payload);
      commit(mutationTypes.CREATE_MEMO_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_MEMO_FAILURE);
    }
  },
};
