import Vue from 'vue';

export default {
  async fetchConfig() {
    return Vue.axios.get(`/api/config/data`);
  },
  async updateConfig(params) {
    return Vue.axios.post(`/api/config/update`, params);
  },
};
