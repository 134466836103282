import Vue from 'vue';

export default {
  async createTask(params) {
    return Vue.axios.post(`/api/task/create`, params);
  },
  async updateTask(params) {
    return Vue.axios.put(`/api/task/update`, params);
  },
  async deleteTask(params) {
    return Vue.axios.delete(`/api/task/delete`, {
      params: params,
    });
  },
  async fetchTask(params) {
    return Vue.axios.get(`/api/task/list`, {
      params: params,
    });
  },
  async getTask(params) {
    return Vue.axios.get(`/api/task/data`, {
      params: params,
    });
  },
};
