import Vue from 'vue';

export default {
  async createAdminNotice(params) {
    return Vue.axios.post(`/api/adminnotice/create`, params);
  },
  async deleteAdminNotice(params) {
    return Vue.axios.delete(`/api/adminnotice/delete`, {
      params: params,
    });
  },
  async fetchAdminNotice(params) {
    return Vue.axios.get(`/api/adminnotice/list`, {
      params: params,
    });
  },
  async getAdminNotice(params) {
    return Vue.axios.get(`/api/adminnotice/get`, {
      params: params,
    });
  },
  async updateAdminNotice(params) {
    return Vue.axios.put(`/api/adminnotice/update`, params);
  },
};
