import Vue from 'vue';

export default {
  async fetchPowerkeyD2b(params) {
    return Vue.axios.get(`/api/powerkey/d2b/`, { params: params });
  },
  async fetchPowerkeyEtc0(params) {
    return Vue.axios.get(`/api/powerkey/etc0/`, { params: params });
  },
  async fetchPowerkeyEtc1(params) {
    return Vue.axios.get(`/api/powerkey/etc1/`, { params: params });
  },
  async fetchPowerkeyG2b(params) {
    return Vue.axios.get(`/api/powerkey/g2b/`, { params: params });
  },
  async fetchPowerkeyKepco(params) {
    return Vue.axios.get(`/api/powerkey/kepco/`, { params: params });
  },
  async fetchPowerkeyLh(params) {
    return Vue.axios.get(`/api/powerkey/lh/`, { params: params });
  },
};
