import moment from 'moment';
import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    startdate: moment()
      .subtract(7, 'day')
      .format('YYYY-MM-DD'),
    enddate: moment().format('YYYY-MM-DD'),
    column: 0,
    search: '',
    manager: '',
    saler: '',
    tuchal: '',
    rank: '',
    ip_amt: 0,
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 20,
    totalElements: 0,
    pages: 1,
  },
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
};

const mutations = {
  [mutationTypes.FETCH]: (state, page) => {
    state.items = [];
    state.loading = true;
    state.pagination.page = page;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }, page = 1) => {
    commit(mutationTypes.FETCH, page);
    try {
      let form = state.form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      const { data } = await api.fetchBidResult({ ...form });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
