import Vue from 'vue';

export default {
  async fetchTuchal(params = {}) {
    //console.log(params);
    return Vue.axios
      .get('/api/stat/tuchal/', {
        params: params,
      })
      .catch((error) => {
        console.error('ERROR : ' + error);
      });
  },
};
