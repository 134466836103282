import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  success: false,
};

const getters = {
  success: (state) => state.success,
};

const mutations = {
  [mutationTypes.CREATE]: (state) => {
    state.success = false;
  },
  [mutationTypes.CREATE_SUCCESS]: (state, response) => {
    state.success = response.success;
  },
  [mutationTypes.CREATE_FAILURE]: () => {
    state.success = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.success = false;
  },
  [mutationTypes.DELETE_SUCCESS]: (state, response) => {
    state.success = response.success;
  },
  [mutationTypes.DELETE_FAILURE]: () => {
    state.success = false;
  },
};

const actions = {
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createChildTask(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteChildTask(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
