import Vue from 'vue';

export default {
  async deleteNoti(params) {
    return Vue.axios.delete(`/api/noti/delete`, {
      params: params,
    });
  },
  async fetchNoti(params) {
    return Vue.axios.get(`/api/noti/list`, {
      params: params,
    });
  },
};
