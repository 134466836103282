import Vue from 'vue';

export default {
  async fetchSmslog(params) {
    return Vue.axios.get(`/api/smslog/data`, {
      params: params,
    });
  },
  async fetchSmslogSentDetail(logId) {
    return Vue.axios.get(`/api/smslog/sent_detail/${logId}/`);
  },
};
