import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  items: {
    analdata: {},
    member: {},
    search: {},
    manager: {},
    commission: {},
  },
};

const getters = {
  items: (state) => state.items,
};

const mutations = {
  [mutationTypes.GET]: (state) => {
    state.items = {
      analdata: {},
      member: {},
      search: {},
      manager: {},
      commission: {},
    };
  },
  [mutationTypes.GET_SUCCESS]: (state, response) => {
    state.items = response;
  },
  [mutationTypes.GET_FAILURE]: (state) => {
    state.items = {
      analdata: {},
      member: {},
      search: {},
      manager: {},
      commission: {},
    };
  },
};

const actions = {
  [actionTypes.GET]: async ({ commit }, payload) => {
    commit(mutationTypes.GET);
    try {
      const { data } = await api.getCongsuc(payload);
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.GET_FAILURE);
      } else {
        commit(mutationTypes.GET_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.GET_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
