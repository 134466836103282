import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  item: {
    unfollow_chk_amt: 10000,
    unfollow_chk_ratio: 0.0,
    input_warn_amount: null,
    input_warn_point: null,
    hide_other_mng: null,
    service_type: 0,
  },
};

const getters = {
  item: (state) => state.item,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.item = {
      unfollow_chk_amt: 10000,
      unfollow_chk_ratio: 0.0,
      input_warn_amount: null,
      input_warn_point: null,
      hide_other_mng: null,
      service_type: 0,
    };
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.item = response;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.item = {
      unfollow_chk_amt: 10000,
      unfollow_chk_ratio: 0.0,
      input_warn_amount: null,
      input_warn_point: null,
      hide_other_mng: null,
      service_type: 0,
    };
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchConfig();
      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async () => {
    try {
      await api.updateConfig({
        ...state.item,
      });
    } catch (e) {
      //console.log(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
