import api from './api';
import * as actionTypes from './action-types';

const M_INIT = 'INIT';
const M_SET_V3BIDINFO = 'SET_V3BIDINFO';

const state = {
  v3bidinfo: [],
  loading: false,
};

const getters = {
  v3bidinfo: (state) => state.v3bidinfo,
  loading: (state) => state.loading,
  getattr: (state) => {
    return (rel, prop, def = '') => {
      return state.v3bidinfo && state.v3bidinfo.hasOwnProperty(rel) && state.v3bidinfo[rel].hasOwnProperty(prop)
        ? state.v3bidinfo[rel][prop]
        : def;
    };
  },
  important_bid: (_, getters) => {
    let important_bid = getters.getattr('v3content', 'important_bid');
    return important_bid.replace(/<hr[\\/]?>/gi, '').replace('&#8228;', '.');
  },
  origin_link: (_, getters) => {
    const origin_lnk = getters.getattr('v3value', 'origin_lnk');
    if (origin_lnk == '' && getters.getattr('v3key', 'whereis') == '03') {
      return 'http://srm.kepco.net';
    } else if (getters.getattr('v3key', 'whereis') == '91') {
      return 'https://ebid.etri.re.kr/ebid/index.do';
    } else {
      return origin_lnk;
    }
  },
  attchd_lnk: (_, getters) => {
    let attchd_lnk = getters.getattr('v3value', 'attchd_lnk');
    return attchd_lnk ? attchd_lnk.split('|').join('<br />') : '';
  },
  files: (_, getters) => {
    return (getters.getattr('data', 'files') || []).join('');
  },
  charger_name: (_, getters) => {
    let charger = getters.getattr('v3value', 'charger');
    return charger ? charger.split('/')[0] : '';
  },
  charger_info: (_, getters) => {
    let charger = getters.getattr('v3value', 'charger');
    return charger
      ? charger
          .split('/')
          .splice(1)
          .join(' / ')
      : '';
  },
  icons: (_, getters) => getters.getattr('data', 'icons'),
  content_bid: (_, getters) => getters.getattr('v3content', 'content_bid'),
  mainforce_nm: (_, getters) => getters.getattr('data', 'mainforce_nm'),
};

const mutations = {
  [M_INIT]: (state) => {
    state.loading = true;
  },
  [M_SET_V3BIDINFO]: (state, data) => {
    state.v3bidinfo = data;
  },
};

const actions = {
  [actionTypes.GET_V3BIDINFO]: async ({ commit }, payload) => {
    try {
      commit(M_INIT, null);
      const { data } = await api.getV3BidInfo({ bidid: payload });
      commit(M_SET_V3BIDINFO, data);
    } catch (e) {
      commit(M_SET_V3BIDINFO, {});
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
